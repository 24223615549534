<template>
  <b-card>
    <g-table
      ref="discount-table"
      :items="items"
      :columns="tableColumns"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: true, permission: 'addBenefitAndDeduction' }"
      :searchInput="{ visiable: true }"
      @on-create="
        (v) => {
          openModal({ englishName: '' });
        }
      "
    >
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('preview')"
            v-permission="'editBenefitAndDeduction'"
            data-action-type="preview"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-prev-icon`"
              icon="EyeIcon"
              class="mx-25 clickable "
              :hidden="true"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission="'editBenefitAndDeduction'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="openModal(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="mx-25 clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission="'deleteBenefitAndDeduction'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>

    <b-sidebar
      id="sidebar-edit"
      ref="sidebar"
      no-close-on-backdrop
      sidebar-class="sidebar-lg"
      bg-variant="white"
      shadow
      backdrop
      no-header
      left
      @hidden="
        () => {
          selectedItem = {};
        }
      "
    >
      <template #default>
        <div class="d-flex justify-content-between bg-primary align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0 text-white">
            {{ selectedItem.id > 0 ? $t('editSalaryItem') : $t('newSalaryItem') }}
          </h5>
        </div>
        <b-card>
          <g-form @submit="save">
            <b-row>
              <!-- code  -->
              <b-col
                v-show="selectedItem.id > 0"
                md="12"
              >
                <g-field
                  id="code"
                  name="code"
                  label-text="code"
                  type="number"
                  disabled
                  :value.sync="selectedItem.code"
                />
              </b-col>

              <!-- arabic Name  -->
              <b-col md="12">
                <g-field
                  id="arabicName"
                  ref="arabicName"
                  rules="required"
                  name="arabicName"
                  label-text="arabicName"
                  :value.sync="selectedItem.arabicName"
                />
              </b-col>

              <!-- english Name  -->
              <b-col md="12">
                <g-field
                  id="englishName"
                  ref="englishName"
                  :value.sync="selectedItem.englishName"
                  name="englishName"
                  label-text="englishName"
                />
              </b-col>

              <!-- item Type -->
              <b-col md="12">
                <b-form-group>
                  <g-field
                    field="select"
                    label-text="type"
                    label="arabicName"
                    rules="required"
                    name="itemType"
                    :options="salaryItemTypes"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :value.sync="selectedItem.itemType"
                  />
                </b-form-group>
              </b-col>

              <!-- nature -->
              <b-col md="12">
                <b-form-group>
                  <g-field
                    field="select"
                    label-text="nature"
                    rules="required"
                    name="nature"
                    label="arabicName"
                    :dir="isRight ? 'rtl' : 'ltr'"
                    :options="salaryItemNatures"
                    :value.sync="selectedItem.nature"
                  />
                </b-form-group>
              </b-col>

              <!-- isShared -->
              <b-col md="6">
                <label
                  style="font-size: 14px;margin-bottom: 7px;"
                  for="general"
                >
                  {{ $t('general') }}
                </label>
                <b-form-group>
                  <b-form-checkbox
                    v-model="selectedItem.isShared"
                    class="mr-0 mt-50"
                    name="is-rtl"
                    inline
                  />
                </b-form-group>
              </b-col>

              <!-- notes -->
              <b-col md="12">
                <b-form-group>
                  <label
                    style="font-size: 14px;margin-bottom: 7px;"
                    for="customer"
                  >
                    {{ $t('notes') }}
                  </label>
                  <b-form-textarea
                    id="textarea"
                    v-model="selectedItem.notes"
                    label="Notes"
                    rows="3"
                    max-rows="6"
                  />
                </b-form-group>
              </b-col>

              <!-- actions -->
              <b-col
                cols="12"
                class="d-flex justify-content-end mt-1"
              >
                <b-button
                  v-permission="$route.meta.permission"
                  class="mx-1"
                  type="submit"
                  variant="primary"
                  data-action-type="save"
                >
                  {{ $t('save') }}
                </b-button>
                <b-button
                  variant="secondary"
                  @click="closeModal"
                >
                  {{ $t('Close') }}
                </b-button>
              </b-col>
            </b-row>
          </g-form>
        </b-card>
      </template>
    </b-sidebar>

  </b-card>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';
import { salaryItemTypes, salaryItemNatures } from '@/libs/acl/Lookups';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
      salaryItemTypes: salaryItemTypes,
      salaryItemNatures: salaryItemNatures,
      currentPage: 1,
      perPage: 25,
      selectedItem: {},
      modal: false,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'code', label: this.$t('code'), sortable: true },
        { key: 'arabicName', label: this.$t('name'), sortable: false },
        {
          key: 'itemTypeName',
          field: 'itemTypeName',
          label: this.$t('type'),
          sortable: true,
          formatter: (key, value, obj) => {
            if (obj.itemType === 'deduction') return `<span class="p-50 main-space badge bg-danger bg-darken-1">${obj.itemTypeName}</span>`;
            if (obj.itemType === 'earning') return `<span class="p-50 main-space badge bg-success bg-darken-1">${obj.itemTypeName}</span>`;
          }
        },
        { key: 'natureName', label: this.$t('nature'), sortable: true },
        { key: 'notes', label: this.$t('notes'), sortable: false },
        { key: 'actions' },
      ];
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getItems() {
      this.get({ url: 'salaryItems' }).then((data) => {
        this.items = data;
        this.items.forEach(item => {
          const itemType = salaryItemTypes.find((val) => val.id === item.itemType);
          const itemNature = salaryItemNatures.find((val) => val.id === item.nature);
          item.itemTypeName = this.isRight ? itemType.arabicName : itemType.englishName;
          item.natureName = this.isRight ? itemNature.arabicName : itemNature.englishName;
        });
      });
    },
    save() {
      if (this.selectedItem.id > 0) {
        this.update({
          url: 'salaryItems',
          data: this.selectedItem,
          id: this.selectedItem.id,
        }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
          this.getItems();
        });
      } else {
        this.create({ url: 'salaryItems', data: this.selectedItem }).then(() => {
          this.closeModal();
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.getItems();
        });
      }
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'salaryItems', id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.getItems();
          });
        }
      );
    },
    closeModal() {
      this.$refs.sidebar.hide();
    },
    openModal(item) {
      this.selectedItem = { ...item };
      this.$root.$emit('bv::toggle::collapse', 'sidebar-edit')
    },
  },
};
</script>

<style></style>
